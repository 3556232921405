import React from 'react'
import "../css/Photogallery.css";
import { Photogallery } from "../components/Photogallery.jsx";
import { gallery2 } from '../Store/Storegallerygorizontal';

function Gallery() {
  const listItemsGallery = gallery2.map((item) => (
    <div className="gallery_content_i" key={item.key}>
      <div className="gallery_content_img">
        <img
          className="gallery_content_img_img"
          src={require(`../img/photogallery/${item.url}`)}
          alt="фото"
        />
      </div>
    </div>
  ));
  return (
    <div className="gallery">
      <Photogallery />
      <div className="gallery2">{listItemsGallery}</div>
    </div>
  );
}

export {Gallery}