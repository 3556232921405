import React, {useState} from "react";
import "../css/Makeorder.css";
import { Modalla } from "../components/Modalla";
import { Order1 } from "../components/Order1";
import arrow from "../icons/arrow.svg";
import rectl_g from "../icons/rectl_g.svg";
import karrot from "../img/karrot.png";
import parnik_bez_gradki from "../img/parnik_bez_gradki.png";
import plus from "../icons/plus.svg";
import present from "../img/present.png";
import gradka from "../img/gradka.png";
import makeorder from "../img/makeorder.png";
import makeorder412 from "../img/makeorder412.png";
import { AiOutlineClose } from "react-icons/ai";

function Makeorder() {
  const [modalActiveMO, setModalActiveMO] = useState(false);
  const [infoSendedFormMO, setInfoSendedFormMO] = useState("");
  const closeModal = () => {
    setModalActiveMO(false);
    setInfoSendedFormMO(!infoSendedFormMO);
  };
  return (
    <>
      <div className="makeorder">
        <div className="makeorder_section">
          <div className="makeorder_block1">
            <div className="makeorder_karrot">
              <img
                className="makeorder_karrot_img"
                src={karrot}
                alt="купить парник хлебницу"
              />
            </div>
            <h2 className="makeorder_h2">
              Закажите парник “Хлебницу”
              <br />
              сейчас и получите грядку
              <br />
              под парник в подарок.
            </h2>
            <h4 className="makeorder_h4">
              сделайте заказ, оплата при получении
            </h4>
            <div className="makeorder_cost">
              <div className="makeorder_oldprice">
                <span className="makeorder_oldprice_1">14200</span>
                <span className="makeorder_oldprice_2">руб</span>
              </div>
              <div className="makeorder_rectl_g">
                <img
                  className="makeorder_rectl_g_img"
                  src={rectl_g}
                  alt="старая цена"
                />
              </div>
              <div className="makeorder_newprice">
                <span className="makeorder_newprice_1">8399</span>
                <span className="makeorder_newprice_2">руб</span>
              </div>
            </div>
            <div className="makeorder_button">
              <button
                onClick={() => setModalActiveMO(true)}
                className="makeorder_button_b"
              >
                <span className="makeorder_button_text">Купить</span>
                <img
                  className="makeorder_button_arrow"
                  src={arrow}
                  alt="купить"
                />
              </button>
            </div>
          </div>
          <div className="makeorder_block2">
            <div className="makeorder_block2_img">
              <img
                className="makeorder_block2_img_img"
                src={parnik_bez_gradki}
                alt="Купить парник Хлебница"
              />
            </div>
            <div className="makeorder_block2_plus">
              <img
                className="makeorder_block2_plus_img"
                src={plus}
                alt="парник Хлебница"
              />
            </div>
            <div className="makeorder_block2_present">
              <img
                className="makeorder_block2_present_img"
                src={present}
                alt="Купить парник Хлебница грядка под парник в подарок"
              />
            </div>
            <div className="makeorder_block2_img2">
              <img
                className="makeorder_block2_img2_img"
                src={gradka}
                alt="грядка под парник в подарок"
              />
            </div>
          </div>
          <div className="makeorder_block_imgs_480">
            <img
              className="makeorder_block_imgs_480_img"
              src={makeorder}
              alt=""
            />
          </div>
          <div className="makeorder_block_imgs_412">
            <img
              className="makeorder_block_imgs_412_img"
              src={makeorder412}
              alt=""
            />
          </div>
        </div>
      </div>
      <Modalla active={modalActiveMO} setActive={setModalActiveMO}>
        <Order1 closeModal={closeModal} />
      </Modalla>
      <div
        className={infoSendedFormMO ? "sended_form active" : "sended_form"}
        onClick={() => setInfoSendedFormMO(!infoSendedFormMO)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose
            onClick={() => setInfoSendedFormMO(infoSendedFormMO)}
          />
        </div>
      </div>
    </>
  );
}

export { Makeorder };
