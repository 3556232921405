import React from 'react'
import "../css/Aboutcomponent.css";
import about_img from "../img/about_img.jpg";

function Aboutcomponent() {
  return (
    <div className="aboutcomponent">
      <div className="aboutcomponent_block">
        <h2 className="aboutcomponent_h2">О нас</h2>
        <div className="aboutcomponent_content">
          <div className="aboutcomponent_text">
            Наша компания “Парник Добрый” специализируется на изготовлении
            парников из цельносварных оцинкованных профилей и
            высококачественного и прочного поликарбоната. Являемся одним из
            ведущих производителей в регионе. Динамично развиваясь, имеем
            возможность эффективно доставлять свой товар по всей России. За годы
            работы мы уже создали несколько десятков тысяч изделий и гарантируем
            их надежность. Продаем недорогие парники с длительным сроком службы
            и под небольшой бюджет. Обеспечим для Вас самые выгодные и вкусные
            цены. Более 62% покупателей, кто уже приобрел у нас парник “Добрый”,
            рекомендуют своим знакомым и друзьям.
          </div>
          <div className="aboutcomponent_img">
            <img
              className="aboutcomponent_img_img"
              src={about_img}
              alt="о нас"
            />
          </div>
        </div>
        <div className="aboutcomponent_glad">
          Будем рады видеть Вас в числе наших клиентов!
        </div>
      </div>
    </div>
  );
}

export {Aboutcomponent}