import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "../css/Questions.css";
import broccoli3 from "../img/broccoli3.jpg";
import broccoli560 from "../img/broccoli560.jpg";
import broccoli490 from "../img/broccoli490.jpg";
import arrow from "../icons/arrow.svg";
import { AiOutlineClose } from "react-icons/ai";

const useValidation = (valuesQ, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [inputvalid, setInputValid] = useState(false);
  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "isEmpty":
          valuesQ ? setEmpty(false) : setEmpty(true);
          break;
        default:
      }
    }
  }, [valuesQ, validations]);

  useEffect(() => {
    if (isEmpty) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [isEmpty]);

  return {
    isEmpty,
    inputvalid,
  };
};

const useInput = (validations) => {
  const [valuesQ, setValuesQ] = useState("");
  const valid = useValidation(valuesQ, validations);
  const handleChangeQ = (e) => {
    setValuesQ(e.target.value);
    // console.log(valuesReview);
  };
  return {
    valuesQ,
    handleChangeQ,
    ...valid,
  };
};

function Questions() {
  const [infoSended, setInfoSended] = useState("");
  const setinfo = () => {
    setInfoSended(!infoSended);
  };
  const form1 = useRef();
  const handleSubmit2 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        "template_s89tk2o",
        form1.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const nameq = useInput({ isEmpty: true });
  const phoneq = useInput({ isEmpty: true });
  
  return (
    <>
      <div className="questions">
        <div className="questions_img">
          <img
            className="questions_img_img"
            src={broccoli3}
            alt="Появились вопросы"
          />
          <img
            className="questions_img_560"
            src={broccoli560}
            alt="Появились вопросы"
          />
          <img
            className="questions_img_490"
            src={broccoli490}
            alt="Появились вопросы"
          />
        </div>
        <div className="questions_block">
          <h2 className="questions_h2">Появились вопросы?</h2>
          <h2 className="questions_h2">Оставьте заявку, и мы позвоним Вам</h2>
          <form
            ref={form1}
            onSubmit={handleSubmit2}
            className="questions_buttons"
          >
            <input
              name="nameq"
              value={nameq.valuesQ}
              onChange={(e) => nameq.handleChangeQ(e)}
              type="text"
              required
              className="questions_button"
              placeholder="Имя"
            />
            <input
              name="phoneq"
              value={phoneq.valuesQ}
              onChange={(e) => phoneq.handleChangeQ(e)}
              type="tel"
              required
              className="questions_button"
              placeholder="Телефон"
            />
            <div className="questions_button_g">
              <button
                disabled={
                  nameq.isEmpty ||
                  phoneq.isEmpty
                }
                onClick={() => setinfo()}
                className="questions_button_green"
              >
                <span className="questions_button_send">Отправить</span>
                <img
                  className="questions_button_green_img"
                  src={arrow}
                  alt="отправить заявку"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={infoSended ? "sended_form active" : "sended_form"}
        onClick={() => setInfoSended(!infoSended)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose onClick={() => setInfoSended(infoSended)} />
        </div>
      </div>
    </>
  );
}

export { Questions };
