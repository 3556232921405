import React from 'react'
import "../css/Aboutcomponent.css";
import {Aboutcomponent} from "../components/Aboutcomponent"

function About() {
  return (
    <div className='about'>
      <Aboutcomponent />
    </div>
  )
}

export {About}