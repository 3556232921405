import React from "react";
import { Header } from "../components/Header.jsx";
import { Footer } from "../components/Footer.jsx";
import { Footermobile } from "../components/Footermobile.jsx";
import { Outlet } from "react-router-dom";
import { Buttonup } from "../components/Buttonup.jsx";

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <Footermobile />
      <Buttonup />
    </>
  );
};
export { Layout };
