import React from "react";
import "../css/Upac.css";
import auto_pack from "../img/auto_pack.jpg";
import parnik_v_upac1 from "../img/parnik_v_upac1.png";
import parnik_v_upac2 from "../img/parnik_v_upac2.png";

function Upac() {
  return (
    <div className="upac">
      <h2 className="upac_title">
        Упаковка компактна и легко помещается
        <br />в любом легковом автомобиле
      </h2>
      <div className="upac_content">
        <div className="upac_content_texts">
          <div className="upac_content_texts_texts">
            <h3 className="upac_content_texts_h3">Размеры парника:</h3>
            <span className="upac_content_text">
              Длина - 2 м<br />
              Ширина - 1 м<br />
              Высота - 1 м
            </span>
            <span className="upac_content_text_bold">
              Размер упаковки (ДхШхВ):
            </span>
            <span className="upac_content_text">
              1.1 х 0,78 х 0,11 м<br />
              Вес - 24,9 кг
            </span>
            <span className="upac_content_text_mobile">
              Длина - 2 м, ширина - 1 м,
            </span>
            <span className="upac_content_text_mobile">
              высота - 1 м, вес - 24,9 кг
            </span>
            <span className="upac_content_text_mobile upac_content_text_mobile_b">
              Размер упаковки (ДхШхВ):
            </span>
            <span className="upac_content_text_mobile">
              1.1 х 0,78 х 0,11 м
            </span>
          </div>
          <span className="upac_content_text2">
            В стоимость входит полный комплект: парник, грядка, каркас, крепёж,
            поликарбонат.
          </span>
        </div>
        <div className="upac_content_images">
          <div className="upac_content_img">
            <img
              className="upac_content_img_img"
              src={auto_pack}
              alt="упаковка парника легко помещается в легковой автомобиль"
            />
          </div>
          <div className="upac_content_imgs">
            <div className="upac_content_imgs_img">
              <img
                className="upac_content_i"
                src={parnik_v_upac1}
                alt="упаковка парника хлебница"
              />
            </div>
            <div className="upac_content_imgs_img upac_content_imgs_img2">
              <img
                className="upac_content_i"
                src={parnik_v_upac2}
                alt="упаковка парника хлебница"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Upac };
