const gallery = [
  {
    name: "Фото1",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg1.jpg",
  },
  {
    name: "Фото2",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg2.jpg",
  },
  {
    name: "Фото3",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg3.jpg",
  },
  {
    name: "Фото4",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg4.jpg",
  },
  {
    name: "Фото5",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg5.jpg",
  },
  {
    name: "Фото6",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg6.jpg",
  },
  {
    name: "Фото7",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg7.jpg",
  },
  {
    name: "Фото8",
    key: Math.round(10000 + Math.random() * 20000),
    url: "fg8.jpg",
  }
//   {
//     name: "Фото9",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg9.jpg",
//   },
//   {
//     name: "Фото10",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg10.jpg",
//   },
//   {
//     name: "Фото11",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg11.jpg",
//   },
//   {
//     name: "Фото12",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg12.jpg",
//   },
//   {
//     name: "Фото13",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg13.jpg",
//   },
//   {
//     name: "Фото14",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg14.jpg",
//   },
//   {
//     name: "Фото15",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg15.jpg",
//   },
//   {
//     name: "Фото16",
//     key: Math.round(10000 + Math.random() * 20000),
//     url: "fg16.jpg",
//   },
];
export { gallery };