import React from 'react'
import "../css/Promotions.css";
import { Makeorder } from '../components/Makeorder';
import { DateScript } from "../components/DateScript.js";

function Promotions() {
  return (
    <div className="promotions">
      <h1 className="promotions_h1">Акции</h1>
      <div className="promotions_block">
        <h2 className="promotions_h2">
          Купите парник “Хлебницу”{<DateScript />}и получите скидку 41%
        </h2>
        <Makeorder />
      </div>
    </div>
  );
}

export {Promotions}