import React, {useState} from "react";
import { Modalla } from "../components/Modalla";
import { Order1 } from "../components/Order1";
import "../css/Offer2.css";
import "../css/Makeorder.css";
import rectl_g from "../icons/rectl_g.svg";
import parnik1 from "../img/parnik1.png";
import arrow from "../icons/arrow.svg";
import tomato3 from "../img/tomato3.jpg";
import cucumber2 from "../img/cucumber2.jpg";
import pepper3 from "../img/pepper3.jpg";
import { AiOutlineClose } from "react-icons/ai";

function Offer2() {
  const [modalActiveOffer, setModalActiveOffer] = useState(false);
  const [infoSendedFormOffer, setInfoSendedFormOffer] = useState("");
  const closeModal = () => {
    setModalActiveOffer(false);
    setInfoSendedFormOffer(!infoSendedFormOffer);
  };
  return (
    <>
      <div className="offer2">
        <h2 className="offer2_h2">
          Готовы собирать богатый урожай
          <br />
          со своего нового парника?
        </h2>
        <div className="offer2_content">
          <div className="offer2_content_texts">
            <h3 className="offer2_h3">Парник “Хлебница”</h3>
            <div className="makeorder_cost offer2_cost">
              <div className="makeorder_oldprice">
                <span className="makeorder_oldprice_1">14200</span>
                <span className="makeorder_oldprice_2">руб</span>
              </div>
              <div className="makeorder_rectl_g">
                <img
                  className="makeorder_rectl_g_img"
                  src={rectl_g}
                  alt="старая цена"
                />
              </div>
              <div className="makeorder_newprice">
                <span className="makeorder_newprice_1">8399</span>
                <span className="makeorder_newprice_2">руб</span>
              </div>
            </div>
            <div className="offer2_text">
              <span className="offer2_t">
                Закажите сейчас, а оплачивайте только при получении
              </span>
              <span className="offer2_t">
                Доставим в любой город России в кратчайшие сроки
              </span>
              <span className="offer2_text_v2">
                Для вашего удобства - доставка до двери
              </span>
              <span className="offer2_text_m">
                (по согласованию с компанией)
              </span>
            </div>
            <div className="offer2_button">
              <button
                onClick={() => setModalActiveOffer(true)}
                className="offer2_button_b"
              >
                <span className="makeorder_button_text">Купить</span>
                <img
                  className="makeorder_button_arrow"
                  src={arrow}
                  alt="заказать"
                />
              </button>
            </div>
            
          </div>
          <div className="offer2_content_img">
            <img
              className="offer2_content_img_img"
              src={parnik1}
              alt="парник хлебница"
            />
          </div>
          </div>
          <div className="offer2_imgs">
              <div className="offer2_img">
                <img
                  className="offer2_img_img"
                  src={tomato3}
                  alt="Готовы собирать богатый урожай?"
                />
              </div>
              <div className="offer2_img">
                <img
                  className="offer2_img_img"
                  src={cucumber2}
                  alt="Готовы собирать богатый урожай?"
                />
              </div>
              <div className="offer2_img">
                <img
                  className="offer2_img_img"
                  src={pepper3}
                  alt="Готовы собирать богатый урожай?"
                />
              </div>
            </div>
      </div>
      <Modalla active={modalActiveOffer} setActive={setModalActiveOffer}>
        <Order1 closeModal={closeModal} />
      </Modalla>
      <div
        className={infoSendedFormOffer ? "sended_form active" : "sended_form"}
        onClick={() => setInfoSendedFormOffer(!infoSendedFormOffer)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose
            onClick={() => setInfoSendedFormOffer(infoSendedFormOffer)}
          />
        </div>
      </div>
    </>
  );
}

export { Offer2 };
