import React from 'react'
import { NavLink } from "react-router-dom";
import "../css/Menunavlinksmobile.css";

function Menunavlinksmobile({ open, setOpen }) {
  return (
    <div className="menunavlinksmobile">
      <nav className="menu-nav">
        <NavLink
          onClick={() => setOpen(!open)}
          to="promotions"
          className="menunavlinksmobile_menu-item"
        >
          Акции
        </NavLink>
        <NavLink
          onClick={() => setOpen(!open)}
          to="about"
          className="menunavlinksmobile_menu-item"
        >
          О компании
        </NavLink>
        <NavLink
          onClick={() => setOpen(!open)}
          to="gallery"
          className="menunavlinksmobile_menu-item"
        >
          Фотогалерея
        </NavLink>
        <NavLink
          onClick={() => setOpen(!open)}
          to="reviews"
          className="menunavlinksmobile_menu-item"
        >
          Отзывы
        </NavLink>
        <NavLink
          onClick={() => setOpen(!open)}
          to="contacts"
          className="menunavlinksmobile_menu-item"
        >
          Контакты
        </NavLink>
      </nav>
    </div>
  );
}

export {Menunavlinksmobile}