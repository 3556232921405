import React from "react";
import "../css/Reviews.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { reviews } from "../Store/Storereviews";
import salad from "../img/salad.jpg";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
// ;
// GrNext;
//import arrow from "../icons/arrow.svg";

function Reviewscomponent() {
  const settings2 = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <GrNext />,
    prevArrow: <GrPrevious />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 884,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 428,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const listReviews = reviews.map((item) => (
    <div className={item.className} key={item.key}>
      <div className="reviews_card_block1">
        <div className="reviews_img">
          <img
            className="reviews_img_img"
            src={require(`../img/reviews/${item.url}`)}
            alt="фото"
          />
        </div>
        <div className="reviews_card_name_city">
          <div className={item.className2}>{item.name}</div>
          <div className="reviews_card_city">{item.city}</div>
        </div>
      </div>
      <div className="reviews_card_block2">
        <span className={item.className3}>{item.text}</span>
      </div>
    </div>
  ));
  return (
    <div className="reviews">
      <div className="reviews_block_title">
        <div className="reviews_img_t">
          <img className="reviews_img_t_img" src={salad} alt="отзывы" />
        </div>
        <div className="reviews_b">
          <h2 className="reviews_title">Отзывы</h2>
        </div>
      </div>
      <div className="reviews_cards">
        <Slider {...settings2}>{listReviews}</Slider>
      </div>
    </div>
  );
}

export { Reviewscomponent };
