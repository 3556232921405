import React from 'react'
import "../css/Benefits.css";
import parsley1 from "../img/parsley1.png";
import parsley2 from "../img/parsley2.png";
import broccoli from "../img/broccoli.png";
import pepper from "../img/pepper.png";
import tomato from "../img/tomato.png";

function Benefits() {
  return (
    <div className="benefits">
      <h2 className="benefits_title">Преимущества конструкции</h2>
      <div className="benefits_block_cards">
        <div className="parsley parsley1">
          <img className="parsley_img" src={parsley2} alt="parsley" />
        </div>
        <div className="benefits_cards">
          <div className="benefits_card benefits_card1">
            <div className="benefits_card_section">
              <div className="benefits_card_section1">
              <div className="benefits_card_img">
                <img
                  className="benefits_card_img_img"
                  src={broccoli}
                  alt="Стабильный, качественный урожай"
                />
              </div>
              <h3 className="benefits_card_title">
                Стабильный, качественный урожай
              </h3>
              </div>
              <div className="benefits_card_text">
                Подходит для выращивания низкорослых овощных культур - перец,
                помидоры, морковь. Рассады овощных культур и цветов. Зелени -
                петрушка, мята, кориандр, салат, базилик.
              </div>
            </div>
          </div>
          <div className="benefits_card benefits_card2">
            <div className="benefits_card_section">
              <div className="benefits_card_section1">
              <div className="benefits_card_img">
                <img
                  className="benefits_card_img_img"
                  src={pepper}
                  alt="Удобство эксплуатации"
                />
              </div>
              <h3 className="benefits_card_title benefits_card_title2">
                Удобство эксплуатации
              </h3>
              </div>
              <div className="benefits_card_text benefits_card_text2">
                Удобный полив. Створки парника открываются с двух сторон.
                Прочный бордюр надежно удерживает грунт. Полив экономный:
                позволяет расходовать столько воды, сколько необходимо
                растениям.
              </div>
            </div>
          </div>
          <div className="benefits_card benefits_card3">
            <div className="benefits_card_section">
              <div className="benefits_card_section1">
              <div className="benefits_card_img">
                <img
                  className="benefits_card_img_img"
                  src={tomato}
                  alt="Лучший микроклимат для растений"
                />
              </div>
              <h3 className="benefits_card_title">
                Лучший микроклимат для растений
              </h3>
              </div>
              <div className="benefits_card_text">
                Хорошая вентиляция, каждое растение получает необходимый
                кислород. В закрытом состоянии парник “Хлебница” надежно
                сохраняет тепло. При ярком солнце парник защищает от
                ультрафиолета.
              </div>
            </div>
          </div>
        </div>
        <div className="parsley parsley2">
          <img className="parsley_img" src={parsley1} alt="parsley" />
        </div>
      </div>
    </div>
  );
  
}

export {Benefits}