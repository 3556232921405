import React from "react";

function DateScript() {
  //   const UsFormatter = new Intl.DateTimeFormat("ru-RU");
  //   console.log(UsFormatter.format(nowDate));
  // 8/9/2021
  //   const months = {
  //     0: "Января",
  //     1: "Февраля",
  //     2: "Марта",
  //     3: "Апреля",
  //     4: "Мая",
  //     5: "Июня",
  //     6: "Июля",
  //     7: "Августа",
  //     8: "Сентября",
  //     9: "Октября",
  //     10: "Ноября",
  //     11: "Декабря",
  //   };
  //   const year = nowDate.getFullYear();
  //   const monthIndex = nowDate.getMonth();
  //   function getDaysInMonth(year, month) {
  //     return new Date(year, month, 0).getDate();
  //   }
  //   const monthName = months[nowDate.getMonth()];
  //   const formatted = { nowDate, monthName };
  //   formatted.toString();
  //   const currentYear = nowDate.getFullYear();
  //   const currentMonth = nowDate.getMonth() + 1; // 👈️ months are 0-based

  //   // 👇️ Current Month
  //   const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
  //   console.log(daysInCurrentMonth); // 👉️ 31
  const nowDate = new Date();
  const nextDate = nowDate.setDate(nowDate.getDate() + 7);
  const longRUFormatter = new Intl.DateTimeFormat("ru-RU", {
    month: "long",
    day: "numeric",
  });
  const dateRU = longRUFormatter.format(nextDate);
  return (
    <div className="nextdate">
      <span className="nextdate_do">до</span>
      <span>{dateRU}</span>
    </div>
  );
}

export { DateScript };
