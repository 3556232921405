import React from 'react'
import "../css/Photogallery.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {gallery} from "../Store/Storegallery"
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

function Photogallery() {
    const settings = {
      // className: "center",
      // centerMode: true,
      infinite: true,
      // centerPadding: "60px",
      slidesToShow: 4,
      slidesToScroll: 4,
      speed: 500,
      dots: true,
      arrows: true,
      nextArrow: <GrNext />,
      prevArrow: <GrPrevious />,
      //rows: 2,
      // slidesPerRow: 2,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 490,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 428,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
      ],
    };
    const listItems = gallery.map((item) => (
      <div className="photogallery_content_i" key={item.key}>
        <div className="photogallery_content_img">
          <img
            className="photogallery_content_img_img"
            src={require(`../img/photogallery/${item.url}`)}
            alt="фото"
          />
        </div>
      </div>
    ));
  return (
    <div className="photogallery">
      <h2 className="photogallery_h2">Фото-галерея</h2>
      <div className="photogallery_content">
        <Slider {...settings}>{listItems}</Slider>
      </div>
    </div>
  );
}

export {Photogallery}