import { Link } from "react-router-dom";
import "../css/Wildberries.css";
import Arrow1 from "../img/Arrow1.svg";
import Arrow2 from "../img/Arrow2.svg";
import Wildberries_Logo1 from "../img/Wildberries_Logo1.png";

function Wildberries() {
  return (
    <>
      <Link
        to="https://www.wildberries.ru/catalog/212775571/detail.aspx?targetUrl=GP&utm_source=parnik&utm_medium=cpc&utm_campaign=1292309-id-saitparnik&utm_content=banner"
        target="_blank"
        className="wb_container"
      >
        <div className="wb_mainblock">
          <div className="wb_text1">
            купить ещё
            <br />
            дешевле
          </div>
          <div className="wb_text1_mobile">купить ещё дешевле</div>
          <div className="wb_images">
            <img className="wb_arrow1" src={Arrow1} alt="arrow" />
            <img className="wb_img" src={Wildberries_Logo1} alt="wb" />
            <img className="wb_arrow2" src={Arrow2} alt="arrow" />
          </div>
          <div className="wb_text2">
            Доставка до
            <br />
            ближайшего ПВЗ
          </div>
          <div className="wb_text2_mobile">Доставка до ближайшего ПВЗ</div>
        </div>
      </Link>
    </>
  );
}
export { Wildberries };
