import { Routes, Route } from "react-router-dom";
import { Layout } from './components/Layout'
import { Mainpage } from "./pages/Mainpage";
import { Promotions } from "./pages/Promotions";
import { About } from "./pages/About";
import { Gallery } from "./pages/Gallery";
import { Reviews } from "./pages/Reviews";
import { Contacts } from "./pages/Contacts";
import { Polisy } from "./pages/Polisy";
import "./css/App.css";
import "./css/Styleguide.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<Mainpage />} />
        <Route path="/" element={<Layout />}>
          <Route path="promotions" element={<Promotions />} />
          <Route path="about" element={<About />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="polisy" element={<Polisy />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
