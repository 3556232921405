import React, { useState } from "react";

import "../css/Header.css";
import { NavLink } from "react-router-dom";
import { Menuheader1300 } from "../components/Menuheader1300";
import { Menunavlinksmobile } from "../components/Menunavlinksmobile";
import { DateScript } from "../components/DateScript.js";
import { FiAlignJustify } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../img/logo.svg";
import phone from "../icons/phone.svg";

function Header() {
  
  const [open, setOpen] = useState(false);
  const hamburgerIcon = (
    <FiAlignJustify
      size="calc(2.1rem + 4vw)"
      color="var(--colorcard2)"
      style={{
        padding: "0px",
        borderRadius: "5px",
      }}
      onClick={() => setOpen(!open)}
    />
  );
  const closeIcon = (
    <AiOutlineClose
      size="50px"
      color="var(--colorcard2)"
      style={{
        padding: "5px",
        borderRadius: "5px",
      }}
      onClick={() => setOpen(!open)}
    />
  );
  return (
    <div className="header">
      <div className="header_wrapper">
        <div className="header_offer">
          <h5 className="header_offer_h5">
            Купите парник “Хлебницу”{<DateScript />} и получите скидку 41%
          </h5>
        </div>
        <div className="header_maincontent">
          <div className="header_block_1">
            <div className="circle1"></div>
            <div className="header_section1">
              <div className="header_section1_logo">
                <NavLink to="/" className="header_section1_logo_link">
                  <div className="header_logo">
                    <img
                      className="header_logo_img"
                      width="175"
                      src={logo}
                      alt="лого парник хлебница"
                    />
                  </div>
                  <h2 className="header_text">
                    Парник
                    <br />
                    Хлебница
                  </h2>
                </NavLink>
              </div>
            </div>
          </div>
          <Menuheader1300 />
          <div className="header_section2">
            <div className="menu">
              <nav className="menu-block">
                <NavLink to="promotions" className="menu-item">
                  Акции
                </NavLink>
                <NavLink to="about" className="menu-item">
                  О компании
                </NavLink>
                <NavLink to="gallery" className="menu-item">
                  Фотогалерея
                </NavLink>
                <NavLink to="reviews" className="menu-item">
                  Отзывы
                </NavLink>
                <NavLink to="contacts" className="menu-item">
                  Контакты
                </NavLink>
              </nav>
            </div>
          </div>
          <div className="header_menu_burger">
            {open ? closeIcon : hamburgerIcon}
            {open && <Menunavlinksmobile />}
          </div>
          <div className="header_section3">
            <div className="header_section3_block">
              <a href="tel:88003330957" className="header_block_phone">
                <div className="header_icon_phone">
                  <img
                    className="header_icon_phone_img"
                    src={phone}
                    alt="телефон"
                  />
                </div>
                <div className="header_phone_number">8-800-333-0957</div>
              </a>
            </div>
            <div className="header_block_text">
              <span>Звонок по России бесплатный</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Header };
