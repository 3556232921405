import React from "react";
import { BsFillArrowUpSquareFill } from "react-icons/bs";

const Buttonup = () => {
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="buttonup">
      <div className="buttonup_b" onClick={ScrollToTop}>
        <BsFillArrowUpSquareFill size="50px" color="var(--colortextmain)" />
      </div>
    </div>
  );
};
export { Buttonup };
