import React, { useRef, useState, useEffect } from "react";
// import ym from "react-yandex-metrika";
import emailjs from "@emailjs/browser";
import { NavLink } from "react-router-dom";
import "../css/Order1.css";
import basket from "../img/basket.png";
import { BsFillCheckSquareFill } from "react-icons/bs";

const useValidation = (valuesOrder, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [inputvalid, setInputValid] = useState(false);
  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "isEmpty":
          valuesOrder ? setEmpty(false) : setEmpty(true);
          break;
        default:
      }
    }
  }, [valuesOrder, validations]);

  useEffect(() => {
    if (isEmpty) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [isEmpty]);

  return {
    isEmpty,
    inputvalid,
  };
};

const useInput = (validations) => {
  const [valuesOrder, setValuesOrder] = useState("");
  const valid = useValidation(valuesOrder, validations);
  const handleChangeOrder = (e) => {
    setValuesOrder(e.target.value);
  };
  return {
    valuesOrder,
    handleChangeOrder,
    ...valid,
  };
};

function Order1({ closeModal }) {
  const [addproduct, setAddproduct] = useState(1);
  const [cost, setCost] = useState(8399);
  const [values, setValues] = useState({
    email: "",
    totalprice: "",
  });
  const addproducts = () => {
    setAddproduct(addproduct + 1);
    setCost(cost + 8399);
  };
  const delproducts = () => {
    setAddproduct(addproduct - 1);
    setCost(cost - 8399);
  };
  const costproducts = (e) => {
    setCost(e.target.value);
    console.log(setCost);
  };

  const handleChangeProduct = (e) => {
    setAddproduct(e.target.value);
  };

  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          window.ym(window.ym.a[0][0], "reachGoal", "forma");
          // closeModal();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleChangeOrderNoRequired = (e) => {
    setValues({ [e.target.name]: e.target.value });
  };

  const syleError = {
    display: "flex",
    color: "red",
    fontSize: "0.9rem",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "500",
    width: "40%",
  };

  const nameorder = useInput({ isEmpty: true });
  const tel = useInput({ isEmpty: true });
  const address = useInput({ isEmpty: true });

  return (
    <>
      <div className="order1">
        <div className="order1_container">
          <form
            ref={form}
            onSubmit={handleSubmit}
            className="form_order1"
            action=""
          >
            <div className="order1_head">
              <div className="order1_basket">
                <div className="order1_quantity">
                  <input
                    className="order1_quantity_text"
                    type="text"
                    value={addproduct}
                    readOnly
                  />
                </div>
                <div className="order1_basket_img">
                  <img className="order1_basket_img_img" src={basket} alt="" />
                </div>
              </div>
              <h3 className="order1_title">Ваш заказ</h3>
            </div>

            <div className="order1_block_product_name">
              <span className="order1_product_name">
                Парник “Хлебница”.
                <br />
                Размер (ДхШхВ): 2м*1м*1м
              </span>
              <input
                className="order1_product_cost"
                type="text"
                value={cost}
                onChange={costproducts}
              />
              <span className="order1_product_cost_rub">руб.</span>
            </div>
            <span className="order1_delivery_head">Доставка</span>
            <div className="order1_delivery_content">
              <span className="order1_delivery_text">
                До пункта ПЭК.
                <br />
                (кроме ДФО)
              </span>
              <span className="order1_delivery_cost">0</span>
              <span className="order1_delivery_rub">руб.</span>
            </div>
            <div className="order1_label_block">
              <div className="order1_fio_block">
                <span className="order1_fio_title">ФИО</span>
                <span className="order1_fio_filled">*</span>
              </div>
              {nameorder.isEmpty && <div style={syleError}>Заполните поле</div>}
            </div>
            <input
              className="order1_fio"
              type="text"
              placeholder="Напишите"
              name="nameorder"
              value={nameorder.valuesOrder}
              onChange={(e) => nameorder.handleChangeOrder(e)}
              required
            />
            <div className="order1_label_block">
              <div className="order1_address_block">
                <span className="order1_address_title">Адрес доставки</span>
                <span className="order1_address_filled">*</span>
              </div>
              {address.isEmpty && <div style={syleError}>Заполните поле</div>}
            </div>
            <input
              name="address"
              value={address.valuesOrder}
              onChange={(e) => address.handleChangeOrder(e)}
              required
              className="order1_address"
              type="text"
              placeholder="Напишите"
            />
            <div className="order1_label_block">
              <div className="order1_tel_block">
                <span className="order1_tel_title">Телефон</span>
                <span className="order1_tel_filled">*</span>
              </div>
              {tel.isEmpty && <div style={syleError}>Заполните поле</div>}
            </div>
            <input
              name="tel"
              value={tel.valuesOrder}
              onChange={(e) => tel.handleChangeOrder(e)}
              required
              className="order1_tel"
              type="tel"
              placeholder="Напишите"
            />
            <span className="order1_email_title">E-mail (необязательно)</span>
            <input
              name="email"
              value={values.email}
              onChange={handleChangeOrderNoRequired}
              className="order1_email"
              type="email"
              placeholder="Напишите"
            />
            <div className="order1_block_quantity">
              <div className="order1_block_quantity_text1">Товар</div>
              <div className="order1_block_quantity_text2">Количество</div>
              <button
                onClick={delproducts}
                className="order1_block_quantity_minus"
                type="button"
                onChange={(e) => e.preventDefault()}
              >
                -
              </button>
              <div className="order1_block_number">
                <input
                  type="text"
                  name="addproduct"
                  value={addproduct}
                  className="order1_number"
                  onChange={handleChangeProduct}
                />
              </div>
              <button
                onClick={addproducts}
                className="order1_block_quantity_plus"
                type="button"
                onChange={(e) => e.preventDefault()}
              >
                +
              </button>
            </div>
            <div className="order1_block_total">
              <span className="order1_total_title">Итого:</span>
              <input
                className="order1_total"
                type="text"
                name="cost"
                value={cost}
                onChange={costproducts}
              />
              <span className="order1_total_rub">руб.</span>
            </div>
            <div className="order1_rectl"></div>
            <span className="order1_payment_title">Оплата при получении</span>
            <div className="order1_polisy_block">
              <div className="order1_polisy_check">
                <BsFillCheckSquareFill size="30px" color="#b8d4a7" />
              </div>
              <span className="order1_polisy_agree">
                Согласие на обработку персональных данных в соответствии с
                <NavLink
                  to="polisy"
                  className="order1_polisy_agree order1_polisy_agree_link"
                >
                  политикой конфиденциальности
                </NavLink>
              </span>
            </div>
            <div className="order1_block_button">
              <button
                disabled={nameorder.isEmpty || address.isEmpty || tel.isEmpty}
                onClick={() => closeModal()}
                className="order1_button"
                type="submit"
              >
                Отправить
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export { Order1 };
