import React from "react";
import "../css/Footer.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../img/logo.svg";
import phone from "../icons/phone.svg";
import smile from "../icons/smile.svg";
import finger5 from "../icons/finger5.svg";
// import finger3 from "../icons/finger3.svg";

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <div className="pre_footer_block">
        <div className="pre_footer_section">
          <div className="pre_footer">
            Получите консультацию прямо сейчас - просто нажмите на номер
            телефона и позвоните нам
          </div>
          <div className="pre_footer_img">
            <img className="pre_footer_img_img" src={smile} alt="" />
          </div>
        </div>
        <div className="pre_footer_img2">
          <img className="pre_footer_img2_img" src={finger5} alt="" />
        </div>
      </div>
      <div className="footer">
        <div className="footer_block">
          <div className="footer_section">
            <div className="footer_section_logo">
              <div className="footer_logo_l">
                <NavLink to="/" className="footer_section1_logo_link">
                  <div className="footer_logo">
                    <img
                      className="footer_logo_img"
                      width="175"
                      src={logo}
                      alt="лого парник хлебница"
                    />
                  </div>
                  <h2 className="footer_logo_text">
                    Парник
                    <br />
                    Хлебница
                  </h2>
                </NavLink>
              </div>
              <div className="footer_logo_copy">
                Добрые грядки &copy;2018 - {year}
              </div>
            </div>
          </div>
          <div className="footer_section_menu">
            <div className="footer_menu">
              <nav className="footer_menu_block">
                <NavLink to="promotions" className="footer_menu_item">
                  Акции
                </NavLink>
                <NavLink to="reviews" className="footer_menu_item">
                  Отзывы
                </NavLink>
                <NavLink to="contacts" className="footer_menu_item">
                  Контакты
                </NavLink>
              </nav>
              <nav className="footer_menu_block">
                <NavLink to="about" className="footer_menu_item">
                  О компании
                </NavLink>
                <NavLink to="gallery" className="footer_menu_item">
                  Фотогалерея
                </NavLink>
              </nav>
            </div>
          </div>
          <div className="footer_section">
            <span className="footer_contact1">г. Череповец</span>
            <span className="footer_contact2">ул. Красная, д. 4г</span>
            <span className="footer_contact2">parnik-hlebnica@mail.ru</span>
          </div>
          <div className="footer_section">
            <div className="footer_section_call">
              Звонок по России бесплатный
            </div>
            <div className="footer_section3_block">
              <a href="tel:88003330957" className="footer_block_phone">
                <div className="footer_icon_phone">
                  <img
                    className="footer_icon_phone_img"
                    src={phone}
                    alt="телефон"
                  />
                </div>
                <div className="footer_phone_number">8-800-333-0957</div>
              </a>
            </div>
            <NavLink to="polisy">
              <div className="footer_polisy">Политика конфиденциальности</div>
            </NavLink>
            <div className="cr_block">
              <div className="cr cr_text">Сайт создан</div>
              <Link
                className="cr cr_a"
                to="https://luckylo.pw/"
                target="_blank"
              >
                luckylo.pw
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Footer };
