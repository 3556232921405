import React from 'react'
import "../css/Contacts.css";
import map from "../img/map.jpg";

function Contacts() {
  return (
    <div className="contacts">
      <h1 className="contacts_h1">Контакты</h1>
      <div className="contacts_content">
        <div className="contacts_text">
          <span className="contacts_text_s1">г. Череповец</span>
          <span className="contacts_text_s2">ул. Красная, д. 4г</span>
          <span className="contacts_text_s2">parnik-hlebnica@mail.ru</span>
          <span className="contacts_text_s2">8-800-550-1723</span>
        </div>
        <div className="contacts_map">
          <img className="contacts_map_img" src={map} alt="контакты" />
        </div>
      </div>
    </div>
  );
}

export {Contacts}