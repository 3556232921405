const reviews = [
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 1,
    name: "Василий Яковлевич",
    // classNameforpage: "reviews_card_forpage_orange",
    className: "reviews_card_orange",
    className2: "reviews_name_white",
    className3: "reviews_text_white",
    city: "г. Москва",
    text: "Купил парник «Хлебница». Оказалось, это именно то, чего не хватало на моем огороде. Очень удобно для рассады и небольших растений. И в эксплуатации очень комфортен открывается в обе стороны. Хороший доступ к растениям, удобно поливать. Пользуюсь с удовольствием, спасибо!",
    url: "review1.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 2,
    name: "Марина Павловна",
    // classNameforpage: "reviews_card_forpage_green1",
    className: "reviews_card_green1",
    className2: "reviews_name_white",
    className3: "reviews_text_white",
    city: "г. Волгоград",
    text: "Смотрела на этот парник Хлебницу уж пару лет. Все думала надо-не надо. Вроде и простым парником раньше обходилась. И все-таки решила заказать. И не пожалела. На самом деле хорошая покупка оказалась. Тепло держит хорошо, доступ к моим растениям очень удобный. Подходишь – открываешь и все мои саженца передо мной. Садить – удобно, поливать – удобно… По периметру насадила редис – жду первый урожай).",
    url: "review2.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 3,
    name: "Виктория Александровна",
    // classNameforpage: "reviews_card_forpage_green2",
    className: "reviews_card_green2",
    className2: "reviews_name_green",
    className3: "reviews_text_green",
    city: "г. Новосибирск",
    text: "Полисадник у меня небольшой, огромную теплицу не поставишь, а сделать все красиво и удобно хочется. Этот парник прекрасно вписался между кустом черной смородины и грядки с укропом. Не надо накрывать теперь полиэтилен, когда хочешь парник закрыть. Просто потянул на себя и все. Надо открыть – одно движение и готово. Думаю теперь, где второй парничек разместить. Приятно смотреть на свой огородик).",
    url: "review3.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 4,
    name: "Татьяна Юрьевна",
    // classNameforpage: "reviews_card_forpage_orange",
    className: "reviews_card_orange",
    className2: "reviews_name_white",
    className3: "reviews_text_white",
    city: "г. Тверь",
    text: "Купила сразу 2. Покупкой довольна. Парник абсолютно стоит денег, которые я за него заплатила. Компактный и легкий, удобно его переносить. Материалы приятные. Оцинкован и защищен от коррозии, да еще и грядка в комплекте. Мне нравиться, когда на огороде все удобно и красиво. Спасибо.",
    url: "review4.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 5,
    name: "Гульнара Фархатовна",
    // classNameforpage: "reviews_card_forpage_green1",
    className: "reviews_card_green1",
    className2: "reviews_name_white",
    className3: "reviews_text_white",
    city: "г. Самара",
    text: "Покупала парник для мамы на день рождения. Пришел вовремя, качество хорошее, как описано. Мама очень довольна, уже засадила рассадой. Говорит и пользоваться удобно и в огороде красиво. Готовиться угощать внуков свежим урожаем. Получился прекрасный подарок. Рекомендую.",
    url: "review5.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 6,
    name: "Владимир Евгеньевич",
    // classNameforpage: "reviews_card_forpage_green2",
    className: "reviews_card_green2",
    className2: "reviews_name_green",
    className3: "reviews_text_green",
    city: "г. Уфа",
    text: "Покупал по рекомендации. Соседи покупали еще 2 года назад. Сначала не совсем понял, зачем нужен этот парник, но соседи были очень довольны, решил тоже приобрести. Оказалось, действительно стоящая вещь. Не надо лезть в теплицу, когда сажаешь рассаду. Подошел – открыл створку и все перед тобой. Температуру держит отлично.",
    url: "review6.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 7,
    name: "Максим Александрович",
    // classNameforpage: "reviews_card_forpage_green1",
    className: "reviews_card_orange",
    className2: "reviews_name_white",
    className3: "reviews_text_white",
    city: "г. Камышин",
    text: "Получил парник с доставкой. Пришел на день раньше, чем обещали при заказе. Оплатил при получении (нынче такое доверие к клиентам редкость, спасибо). Хорошо упакован в коробку. Все есть. Собирал сам по инструкции, собирается хорошо. Хорошо, что в комплекте есть грядка – очень продуманная конструкция. Все части друг к другу подходят, щелей нет. Покупкой доволен.",
    url: "review7.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 8,
    name: "Сергей Петрович",
    // classNameforpage: "reviews_card_forpage_green2",
    className: "reviews_card_green1",
    className2: "reviews_name_white",
    className3: "reviews_text_white",
    city: "г. Железнодорожный",
    text: "Понравилась цена парника. Да еще и грядка в комплекте, и поликарбонат. Были сомнения, что за такую цену будет что-нибудь не так. Но оплата при получении, так что решил заказать и все сначала проверить. Когда получал, сначала осмотрел, все проверил – действительно все хорошо. Затем оплатил. Буду заказывать еще. Жене очень понравилось, хотим еще теще сделать подарок.",
    url: "review8.png",
  },
];
export { reviews };